import React, { useEffect, useState } from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Servicepage from '../Menservice/Servicepage'
import Footer from '../Footer/Main'
import { getStrapiData } from '../../api.controller'

function Main() {
    const [data, setData] = useState();

    useEffect(() => {
        getStrapiData("consultancy-services-page").then(response => {
            console.log(response);
            setData(response.data);
        });
    }, []);

    return (
        <>
            <div className="page-wrapper">
                <Header />
                <Breadcrumb data={data} subtitle="Home" title="Consultancy Services" />
                <section className="service-one service-one--page">
                    <div className="container">



                        <p>We specialize in Human Resource (HR) Consultancy Services and provide efficient services to our esteemed clients in this field.

                        </p>

                        <p>
                            Our HR Consultancy is highly regarded by our clients as it helps in a smooth functionality of various operations of the organization. Our professionals have high experience and expertise in this field and are easily able to identify various inefficient procedures and systems that are affecting the productivity and performance of the employees.
                        </p>


                        <p>Consultancy services we provide:</p>

                        <ul className="about-one__content__list">
                            <li>
                                <span className="fa fa-check-circle" />
                                HR Consultancy
                            </li>
                            <li>
                                <span className="fa fa-check-circle" />
                                HR Process Management
                            </li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Visa Processing
                            </li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Work Visa Sponsorhip
                            </li>
                            <li>
                                <span className="fa fa-check-circle" />
                                Meet and Greet
                            </li>

                        </ul>


                    </div>
                </section>
                <Footer />
            </div>

        </>
    )
}

export default Main