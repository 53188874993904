import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { getStrapiData } from './api.controller';
import LoadingPage from './components/LoadingPage/LoadingPage.tsx';

const root = ReactDOM.createRoot(document.getElementById('root'));
function updateCSSVariables(data) {
  const root = document.documentElement;
  root.style.setProperty('--hiredots-primary', data.primary);
  root.style.setProperty('--hiredots-base', data.secondary);
}

let themeData = {};
getStrapiData("theme").then(res => {
  themeData = res.data;
  updateCSSVariables(themeData);
  root.render(
    <App />
  );
});

root.render(
  <LoadingPage />
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
