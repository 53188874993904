import React, { useState, useEffect } from 'react';
import { getStrapiData } from '../../api.controller';

function Funfact({ data }) {

    const [counts, setCounts] = useState();


    useEffect(() => {
        if (data) {
            setCounts(data.stats);
        }
    }, [data]);

    return (
        <div>
            {counts && <section className="funfact-one">
                <div className="container">
                    <div className="row">

                        {counts.map((item, index) => {

                            return <div key={index} className="col-lg-3 col-md-6">
                                <div className="funfact-one__item">
                                    <div className="funfact-one__icon">
                                        <span className={item.icon} />
                                    </div>
                                    <div className="funfact-one__count count-box">
                                        <span className="count">{item.count}</span>
                                    </div>
                                    <p className="funfact-one__text">{item.text}</p>
                                </div>
                            </div>
                        })}

                    </div>
                </div>
            </section>}
        </div>
    )
}

export default Funfact