import React, { useEffect, useRef, useState } from 'react';
import { createNewAdmin } from '../../api.controller.js';
import Swal from 'sweetalert2';
import { showNotification, showLoading } from '../../utils.ts';



function Main() {
    const fileRef = useRef(null);
    let [formData, setFormData] = useState({
        fullName: "",
        email: "",
        phone: "",
        password: null
    });
    let [user, setUser] = useState();


    useEffect(() => {
        const stringifiedUser = localStorage.getItem("userData");
        if (stringifiedUser) {
            const _user = JSON.parse(stringifiedUser);
            setUser(_user);
        }
    }, []);


    const resetFile = () => {
        fileRef.current.value = null;
    }

    const _createNewAdmin = async () => {
        const data = new FormData();
        for (const key in formData) {
            if (Object.hasOwnProperty.call(formData, key)) {
                const item = formData[key];
                data.append(key, item);
            }
        }

        if (!formData.fullName) {
            showNotification("An Error Occured", "Full name is required", "error");
            return;
        }

        if (!formData.email) {
            showNotification("An Error Occured", "Email is required", "error");
            return;
        }

        if (!formData.phone) {
            showNotification("An Error Occured", "Phone is required", "error");
            return;
        }

        if (!formData.password) {
            showNotification("An Error Occured", "Password is required", "error");
            return;
        }


        try {
            showLoading("Please wait", "Submitting Data");
            const response = await createNewAdmin(data);
            Swal.close();
            showNotification("Great!", "Admin has been successfully created", "success");
            setFormData({
                fullName: "",
                email: "",
                phone: "",
                password: ""
            });
            console.log(response);
        } catch (error) {
            const message = error?.response?.data?.message || "An error occured";
            showNotification("Sorry", message, "error");
            console.log(error);
        }
    }
    return (
        <div>
            <section className="contact-one pt-100 pb-100">
                {user && user.userType.toUpperCase() == "ADMIN" ? <div className="container">
                    <div className="contact-one__inner">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="contact-one__content">
                                    <div className="sec-title text-@@textAlign">
                                        <h6 className="sec-title__tagline">Add Admin</h6>
                                        {/* <h3 className="sec-title__title">
                                            Feel Free to share job openings<br /> with Careers In Ghana
                                        </h3> */}
                                    </div>
                                    {/* <p className="contact-one__text">
                                        Careers In Ghana is dedicated to connecting top talent with leading employers across the country. We invite you to share your job openings with us and benefit from our extensive network and resources.
                                    </p> */}

                                </div>
                                <div >
                                    <form className=" contact-form-validated form-one wow fadeInUp" data-wow-duration="1500ms" >
                                        <div className="form-one__group">

                                            <div className="form-one__control form-one__control--full">
                                                <input type="text"
                                                    value={formData.fullName}
                                                    onChange={(event) => {
                                                        const fullName = event.target.value;
                                                        setFormData({ ...formData, fullName });
                                                    }}
                                                    name="fullName" placeholder="Full Name" />
                                            </div>
                                            <div className="form-one__control form-one__control--full">
                                                <input type="text"
                                                    value={formData.email}
                                                    onChange={(event) => {
                                                        const email = event.target.value;
                                                        setFormData({ ...formData, email });
                                                    }}
                                                    name="email" placeholder="Email" />
                                            </div>
                                            <div className="form-one__control form-one__control--full">
                                                <input type="text"
                                                    value={formData.phone}
                                                    onChange={(event) => {
                                                        const phone = event.target.value;
                                                        setFormData({ ...formData, phone });
                                                    }}
                                                    name="phone" placeholder="Phone" />
                                            </div>
                                            <div className="form-one__control form-one__control--full">
                                                <input type="password"
                                                    value={formData.password}
                                                    onChange={(event) => {
                                                        const password = event.target.value;
                                                        setFormData({ ...formData, password });
                                                    }}
                                                    name="password" placeholder="Password" />
                                            </div>

                                            <div className="form-one__control form-one__control--full">
                                                <button onClick={(event) => {
                                                    event.preventDefault();
                                                    _createNewAdmin();

                                                }} type="submit" className="hiredots-btn hiredots-btn--white">
                                                    <span>Submit</span>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : <h3 className="text-center my-5">Unauthorized</h3>}
            </section>
        </div>
    )
}

export default Main