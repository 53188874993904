import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Logo from '../../assets/images/logo-dark.png'
import Logo1 from '../../assets/images/logo-light.png'
import WOW from 'wowjs'
import { getStrapiData } from '../../api.controller'


function Main() {

    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [homeDrop, sethomeDrop] = useState(false)
    const [headerDrop, setheaderDrop] = useState(false)
    const [pageDrop, setpageDrop] = useState(false)
    const [servicesDrop, setservicesDrop] = useState(false)
    const [projectsDrop, setprojectsDrop] = useState(false)
    const [blogDrop, setblogDrop] = useState(false)
    const [search, setsearch] = useState(false)
    const [user, setUser] = useState();
    const location = useLocation()
    const path = location.pathname
    const [menu, setmenu] = useState({})
    const navigate = useNavigate();
    const [socialMediaLinks, setSocialMediaLinks] = useState();
    const activeMenu = () => {

        if (path === "/" || path === "/") {
            setmenu({ home: true })
        } else if (path === "/about") {
            setmenu({ about: true })
        } else if (path === "/pages" || path === "/pages") {
            setmenu({ pages: true })
        } else if (path === "/projects" || path === "/projects") {
            setmenu({ projects: true })
        } else if (path === "/services" || path === "/services") {
            setmenu({ services: true })
        } else if (path === "/blog" || path === "/blog") {
            setmenu({ blog: true })
        }
    }


    const [sticky, setSticky] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        const stringifiedUser = localStorage.getItem("userData");
        if (stringifiedUser) {
            const _user = JSON.parse(stringifiedUser);
            setUser(_user);
        }
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, []);


    useEffect(() => {
        getStrapiData("social-media-links").then(response => {
            setSocialMediaLinks(response.data);
        }).catch(err => {
            console.log(err);
        })
    }, []);

    useEffect(() => {
        window.scroll(0, 0)
        new WOW.WOW({
            live: false
        }).init();
        activeMenu()
    }, [path]);
    const isSticky = () => {
        const scrollTop = window.scrollY;
        scrollTop >= 141 ? setSticky(true) : setSticky(false);
    }

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    const closeMenu = () => {
        setMobileMenuOpen(false);
    };

    return (
        <>
            <div className="topbar-one">
                <div className="container-fluid">
                    <div className="topbar-one__inner">
                        <ul className="list-unstyled topbar-one__info">
                            <li className="topbar-one__info__item">
                                <i className="fas fa-envelope" />
                                <Link to="mailto:employers@careersinghana.com">employers@careersinghana.com</Link>
                            </li>
                            <li className="topbar-one__info__item">
                                <i className="fas fa-phone-square" />
                                <Link to="tel:+233500043033">(+233)500043033 /(0)500865676</Link>
                            </li>
                            {
                                user && <div className="ms-3"> Hi <span className="fw-bold">{user?.fullName}</span>
                                    <i onClick={
                                        (event) => {
                                            localStorage.removeItem("userData");
                                            window.location.reload();
                                        }
                                    }
                                        style={{ cursor: "pointer" }}
                                        className="fa fa-sign-out-alt ms-2" />

                                    <span className="badge bg-secondary ms-2">{user?.userType}</span>
                                </div>
                            }
                        </ul>
                        <div className="topbar-one__right">
                            <div className="topbar-one__nav">
                                {user && user?.userType == "JOB_SEEKER" && <ul>
                                    <li>
                                        <Link to="/account">Account</Link>
                                    </li>
                                    <li>
                                        <Link onClick={(event) => {
                                            event.preventDefault();
                                            localStorage.removeItem("userData");
                                            window.location.reload();
                                        }} to="/logout">Logout</Link>
                                    </li>

                                </ul>}
                                {user && user?.userType == "ADMIN" && <ul>
                                    <li>
                                        <Link to="/users">Users</Link>
                                    </li>

                                    <li>
                                        <Link to="/resumes">Resumes</Link>
                                    </li>

                                    <li>
                                        <Link onClick={(event) => {
                                            event.preventDefault();
                                            localStorage.removeItem("userData");
                                            window.location.reload();
                                        }} to="/logout">Logout</Link>
                                    </li>

                                </ul>}
                                {user && user?.userType == "COMPANY" && <ul>
                                    <li>
                                        <Link to="/account">Account</Link>
                                    </li>
                                    <li>
                                        <Link onClick={(event) => {
                                            event.preventDefault();
                                            localStorage.removeItem("userData");
                                            window.location.reload();
                                        }} to="/logout">Logout</Link>
                                    </li>

                                </ul>}
                            </div>
                            {socialMediaLinks && <div className="topbar-one__social">
                                {socialMediaLinks.twitter && <Link to={socialMediaLinks.twitter}>
                                    <i className="fab fa-twitter" aria-hidden="true" />
                                    <span className="sr-only">Twitter</span>
                                </Link>}
                                {socialMediaLinks.facebook && <Link to={socialMediaLinks.facebook}>
                                    <i className="fab fa-facebook" aria-hidden="true" />
                                    <span className="sr-only">Facebook</span>
                                </Link>}

                                {socialMediaLinks.instagram && <Link to={socialMediaLinks.instagram}>
                                    <i className="fab fa-instagram" aria-hidden="true" />
                                    <span className="sr-only">Instagram</span>
                                </Link>}
                                {socialMediaLinks.youtube && <Link to={socialMediaLinks.youtube}>
                                    <i className="fab fa-youtube" aria-hidden="true" />
                                    <span className="sr-only">Youtube</span>
                                </Link>}
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
            <header className={`main-header sticky-header sticky-header--normal ${sticky && "main-header sticky-header sticky-header--cloned active"}`}>
                <div className="main-header__inner">
                    <div className="main-header__logo">
                        <Link to="/">
                            <img src={Logo} alt="Hiredots HTML" width={125} />
                        </Link>
                    </div>
                    {/* <Link to="#" className="search-toggler main-header__search" onClick={() => setsearch(true)}>
                        <i className="icon-magnifying-glass" aria-hidden="true" />
                        <span className="sr-only">Search</span>
                    </Link> */}
                    <div className="main-header__menu">
                        <nav className="main-header__nav main-menu">
                            <ul className="main-menu__list">
                                <li className={` ${menu.home && "current"}`}>
                                    <Link to="/">Home</Link>

                                </li>
                                <li className={` ${menu.about && "current"}`}>
                                    <Link to="/about">About</Link>
                                </li>

                                <li className={`dropdown ${menu.pages && "current"}`}>
                                    <Link to="#">Employers</Link>
                                    <ul>
                                        <li>
                                            <Link to="/executive-search">Executive Search</Link>
                                        </li>
                                        <li>
                                            <Link to="/recruitment">Recruitment</Link>
                                        </li>
                                        <li>
                                            <Link to="/payroll-outsourcing">Payroll Outsourcing</Link>
                                        </li>
                                        <li>
                                            <Link to="/background-checks">Background Checks</Link>
                                        </li>
                                        <li>
                                            <Link to="/consultancy-services">Consultancy Services</Link>
                                        </li>
                                        <li>
                                            <Link to="/our-clients">Our Clients</Link>
                                        </li>
                                        <li>
                                            <Link to="/employer-of-record">Employer of Record</Link>
                                        </li>
                                        {/* <li>
                                            <Link to="/contact">Contact</Link>
                                        </li> */}
                                        {/* <li>
                                            <Link to="/contact">Resources</Link>
                                        </li> */}
                                    </ul>
                                </li>
                                <li className={`dropdown ${menu.pages && "current"}`}>
                                    <Link to="#">Job Seekers</Link>
                                    <ul>
                                        <li>
                                            <Link to="/jobs">Jobs</Link>
                                        </li>
                                        <li>
                                            <Link to="https://www.accurateskills.com/">Aptitude Test</Link>
                                        </li>
                                        {/* <li>
                                            <Link to="/contact">Contact</Link>
                                        </li> */}

                                    </ul>
                                </li>
                                <li>
                                    <Link to="/contact">Contact</Link>
                                </li>
                                <li>
                                    <Link to="/jobs">Jobs</Link>
                                </li>
                                {/* <li>
                                    <Link to="/gallery">Gallery</Link>
                                </li> */}
                                <li className={`dropdown ${menu.pages && "current"}`}>
                                    <Link to="#">
                                        Resources
                                    </Link>
                                    <ul>
                                        <li>
                                            <Link to="/career-listings">Career School</Link>
                                        </li>
                                        {/* <li>
                                            <Link to="/career-advice">Career Advice</Link>
                                        </li>
                                        <li>
                                            <Link to="/interview-skills">Interview Skills</Link>
                                        </li> */}
                                        <li>
                                            <Link to="/gallery">Gallery</Link>
                                        </li>
                                        <li>
                                            <Link to="/blog">Blog</Link>
                                        </li>


                                    </ul>
                                </li>

                                {!user && <li>
                                    <Link to="/login">Login</Link>
                                </li>}
                                {/* <li className={`dropdown ${menu.pages && "current"}`}>
                                    <Link to="#">Pages</Link>
                                    <ul>
                                        <li>
                                            <Link to="/teamdetails">Team Details</Link>
                                        </li>
                                        <li>
                                            <Link to="/jobs">Jobs</Link>
                                        </li>
                                        <li>
                                            <Link to="/history">History</Link>
                                        </li>
                                        <li>
                                            <Link to="/faq">FAQs</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className={`dropdown ${menu.services && "current"}`}>
                                    <Link to="/services">Services</Link>
                                    <ul>
                                        <li>
                                            <Link to="/services">Services</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-consulting">HR Consulting</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-resources">Tech Resources</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-hydro">Staffing Solution</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-program">Corporate Program</Link>
                                        </li>
                                        <li>
                                            {" "}
                                            <Link to="/service-training">Leadership Training</Link>
                                        </li>
                                        <li>
                                            <Link to="/service-management">Staff Management</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className={`dropdown ${menu.projects && ""}`}>
                                    <Link to="/project">Projects</Link>
                                    <ul>
                                        <li>
                                            <Link to="/project">Projects</Link>
                                        </li>
                                        <li>
                                            <Link to="/project-details">Projects Details</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className={`dropdown ${menu.blog && ""}`}>
                                    <Link to="/blog">News</Link>
                                    <ul>
                                        <li>
                                            <Link to="/blog">News</Link>
                                        </li>
                                        <li>
                                            <Link to="/blog-details">News Details</Link>
                                        </li>
                                    </ul>
                                </li> */}

                            </ul>
                        </nav>
                        {/* <div className="main-header__call">
                            <i className="icon-telephone" />
                            <Link to="tel:+233303934184">(+233)303934184</Link>
                        </div> */}
                    </div>
                    <div className="main-header__link">
                        <Link className="main-header__btn" to="/contact">
                            Book <br /> Appointment
                        </Link>
                    </div>
                    <div className="mobile-nav__btn mobile-nav__toggler" onClick={toggleMobileMenu}>
                        <span />
                        <span />
                        <span />
                    </div>
                </div>
            </header>

            {search &&
                <div className="search-popup active">
                    <div className="search-popup__overlay search-toggler" onClick={() => setsearch(false)} />
                    <div className="search-popup__content">
                        <form role="search" method="get" className="search-popup__form" action="#">
                            <input type="text" id="search" placeholder="Search Here..." />
                            <button type="submit"
                                aria-label="search submit" className="hiredots-btn hiredots-btn--base">
                                <span>
                                    <i className="icon-magnifying-glass" />
                                </span>
                            </button>
                        </form>
                    </div>
                </div>
            }

            {mobileMenuOpen &&
                <div className={`mobile-nav__wrapper ${mobileMenuOpen ? "expanded" : ""}`}>
                    <div className="mobile-nav__overlay mobile-nav__toggler" onClick={() => setIsDropdownOpen(false)} />
                    <div className="mobile-nav__content">
                        <span className="mobile-nav__close mobile-nav__toggler" onClick={() => setMobileMenuOpen(false)}>
                            <i className="fa fa-times" />
                        </span>
                        <div className="logo-box">
                            <Link to="/" aria-label="logo image" onClick={() => setIsDropdownOpen(false)}>
                                <img src={Logo1} width={155} alt="" />
                            </Link>
                        </div>
                        {user && <div className="my-4 text-white">
                            Hi  <span className="fw-bold">{user?.fullName}</span> <span className="badge bg-secondary">{user?.userType}</span>
                        </div>}
                        <div className="mobile-nav__container">
                            <ul className="main-menu__list">

                                <ul style={{ display: "block" }}>
                                    <li className={` ${menu.home && "current"}`}>
                                        <Link to="/">Home</Link>

                                    </li>
                                    <li className={` ${menu.about && "current"}`}>
                                        <Link to="/about">About</Link>
                                    </li>
                                    {/* servicesDrop */}
                                    <li className={`dropdown current`}>
                                        <Link to="#" className={servicesDrop ? "expanded" : ""}>Employers<button aria-label="dropdown toggler" className={servicesDrop ? "expanded" : ""} onClick={() => setservicesDrop(servicesDrop ? false : true)} ><i className="fa fa-angle-down"></i></button></Link>
                                        {servicesDrop && <ul style={{ display: "block", paddingLeft: 15 }}>
                                            <li>
                                                <Link to="/executive-search">Executive Search</Link>
                                            </li>
                                            <li>
                                                <Link to="/recruitment">Recruitment</Link>
                                            </li>
                                            <li>
                                                <Link to="/payroll-outsourcing">Payroll Outsourcing</Link>
                                            </li>
                                            <li>
                                                <Link to="/background-checks">Background Checks</Link>
                                            </li>
                                            <li>
                                                <Link to="/consultancy-services">Consultancy Services</Link>
                                            </li>
                                            <li>
                                                <Link to="/our-clients">Our Clients</Link>
                                            </li>
                                            <li>
                                                <Link to="/employer-of-record">Employer of Record</Link>
                                            </li>
                                            {/* <li>
                                                <Link to="/contact">Contact</Link>
                                            </li> */}

                                        </ul>
                                        }
                                    </li>

                                    <li className={`dropdown current`}>
                                        <Link to="#" className={pageDrop ? "expanded" : ""}>Job Seekers<button aria-label="dropdown toggler" className={pageDrop ? "expanded" : ""} onClick={() => setpageDrop(pageDrop ? false : true)} ><i className="fa fa-angle-down"></i></button></Link>
                                        {pageDrop &&
                                            <ul style={{ display: "block", paddingLeft: 15 }}>
                                                <li>
                                                    <Link to="/jobs">Jobs</Link>
                                                </li>
                                                <li>
                                                    <Link to="https://www.accurateskills.com/">Aptitude Test</Link>
                                                </li>
                                                {/* <li>
                                                    <Link to="/contact">Contact</Link>
                                                </li> */}

                                            </ul>}
                                    </li>
                                    {/* <li>
                                        <Link to="/contact">Contact</Link>
                                    </li> */}

                                    <li>
                                        <Link to="/jobs">Jobs</Link>
                                    </li>
                                    {user && <li>
                                        <Link to="/account">Account</Link>
                                    </li>}
                                    <li className={`dropdown current`}>
                                        <Link to="#" className={headerDrop ? "expanded" : ""}>Resources<button aria-label="dropdown toggler" className={headerDrop ? "expanded" : ""} onClick={() => setheaderDrop(headerDrop ? false : true)} ><i className="fa fa-angle-down"></i></button></Link>
                                        {headerDrop &&
                                            <ul style={{ display: "block", paddingLeft: 15 }}>
                                                <li>
                                                    <Link to="/career-listings">Career School</Link>
                                                </li>
                                                {/* <li>
                                            <Link to="/career-advice">Career Advice</Link>
                                        </li>
                                        <li>
                                            <Link to="/interview-skills">Interview Skills</Link>
                                        </li> */}
                                                <li>
                                                    <Link to="/gallery">Gallery</Link>
                                                </li>
                                                <li>
                                                    <Link to="/blog">Blog</Link>
                                                </li>
                                            </ul>}
                                    </li>

                                    {!user && <li>
                                        <Link to="/login">Login</Link>
                                    </li>}

                                </ul>
                                {!user && <ul style={{ display: "block" }}> <li>
                                    <Link to="/login">Login</Link>
                                </li></ul>}
                                {
                                    user && <ul style={{ display: "block" }}><li>
                                        <Link onClick={(event) => {
                                            event.preventDefault();
                                            localStorage.removeItem("userData");
                                            window.location.reload();
                                        }} style={{ display: "flex", justifyContent: "start" }} to="#">
                                            Logout
                                            <i
                                                style={{ cursor: "pointer" }}
                                                className="fa fa-sign-out-alt ms-2" />
                                        </Link>
                                    </li></ul>
                                }
                            </ul>
                        </div>
                        <ul className="mobile-nav__contact list-unstyled">
                            <li>
                                {/* <i className="fa fa-envelope" /> */}
                                <Link to="mailto:employers@careersinghana.com">
                                    employers@careersinghana.com</Link>
                            </li>
                            <li>
                                <i className="fa fa-phone-alt" />
                                <Link to="tel:+233500043033">(+233)500043033 /(0)500865676</Link>
                            </li>
                        </ul>
                        {socialMediaLinks && <div className="mobile-nav__social">
                            {socialMediaLinks.twitter && <Link to={socialMediaLinks.twitter}>
                                <i className="fab fa-twitter" aria-hidden="true" />
                                <span className="sr-only">Twitter</span>
                            </Link>}
                            {socialMediaLinks.facebook && <Link to={socialMediaLinks.facebook}>
                                <i className="fab fa-facebook" aria-hidden="true" />
                                <span className="sr-only">Facebook</span>
                            </Link>}

                            {socialMediaLinks.instagram && <Link to={socialMediaLinks.instagram}>
                                <i className="fab fa-instagram" aria-hidden="true" />
                                <span className="sr-only">Instagram</span>
                            </Link>}
                            {socialMediaLinks.youtube && <Link to={socialMediaLinks.youtube}>
                                <i className="fab fa-youtube" aria-hidden="true" />
                                <span className="sr-only">Youtube</span>
                            </Link>}
                        </div>}
                    </div >
                </div >
            }

        </>
    )
}

export default Main