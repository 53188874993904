import React, { useEffect, useState } from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Servicepage from '../Menservice/Servicepage'
import Footer from '../Footer/Main'
import { getStrapiData, updateUserFile } from '../../api.controller'
import { capitalize } from 'lodash'
import { Link, useNavigate } from 'react-router-dom'

function Main() {
    const [data, setData] = useState();
    const [user, setUser] = useState();
    const [cvFile, setCvFile] = useState();
    const [logo, setLogo] = useState();
    const navigate = useNavigate();


    useEffect(() => {
        getStrapiData("recruitment").then(response => {
            setData(response.data);
        }).catch(err => {
            console.log(err);
        })

        const stringifiedUser = localStorage.getItem("userData");
        if (stringifiedUser) {
            const _user = JSON.parse(stringifiedUser);
            setUser(_user);
        }
    }, []);

    const uploadCV = async () => {
        try {
            if (!cvFile) {
                alert("You need to upload a CV");
                return;
            }
            const data = await updateUserFile(user._id, { file: cvFile });
            localStorage.setItem("userData", JSON.stringify({ ...data?.data?.user }));
            alert("You have successfully updated cv");
            navigate(0);
        } catch (error) {
            alert(error?.message);
        }
    }
    const uploadLogo = async () => {
        try {
            if (!logo) {
                alert("You need to upload an image for the logo");
                return;
            }
            const data = await updateUserFile(user._id, { file: logo });
            localStorage.setItem("userData", JSON.stringify({ ...data?.data?.user }));
            alert("You have successfully updated logo");
            navigate(0);
        } catch (error) {
            alert(error?.message);
        }
    }

    return (
        <>
            <div className="page-wrapper">
                <Header />
                <Breadcrumb data={data} subtitle="Home" title="Account" />
                <section className="service-one service-one--page">
                    <div className="container">
                        {user ? <><h1>Welcome {capitalize(user?.fullName)}</h1>

                            {
                                user?.userType === "JOB_SEEKER" && <>
                                    <h4>Update Your CV and Land Your Dream Job!</h4>
                                    <p className="mt-4">
                                        Keep your CV up to date and stay ready for the best career opportunities. A well-maintained CV shows employers that you're serious about your career growth. Update your skills, experiences, and qualifications to stand out from the competition and increase your chances of getting hired.
                                    </p>
                                    <p className="mt-5 fw-bold">Take the next step in your career by updating your CV today!</p>
                                    {user && <Link target='_blank' style={{ fontSize: 25, textDecoration: 'underline' }} to={`${process.env.REACT_APP_FILE_BASE_URL}/${user.file}`}><i className="fa fa-file me-2"></i> View Your Current CV</Link>}
                                    <div value={cvFile} onChange={(event) => {
                                        const cvFile = event.target?.files[0];
                                        setCvFile(cvFile);
                                    }} style={{ border: "1px solid #101010" }} className=" mt-4 p-3 form-one__control form-one__control--full">
                                        <div>
                                            <label className="fw-bold">CV File</label>
                                        </div>
                                        <input type="file" className=" mt-2" name="companyLogo" placeholder="Company Logo" />
                                    </div>
                                    <div className="form-one__control form-one__control--full mt-3">
                                        <button onClick={(event) => {
                                            event.preventDefault();
                                            uploadCV();
                                        }} type="submit" className="hiredots-btn">
                                            <span>Upload</span>
                                        </button>

                                    </div>
                                </>
                            }

                            {
                                user?.userType === "COMPANY" && <>
                                    <h4>Update Your Company Logo</h4>
                                    <p className="mt-4">
                                        Enhance your brand's identity by updating your company logo. A fresh logo ensures that your brand stays current and visually appealing. Upload your new logo to reflect your company's growth and modernize your brand image.
                                    </p>
                                    <p className="mt-5 fw-bold">Upload your new logo now and give your brand a fresh look!</p>
                                    {user && <img style={{ width: 150 }} src={`${process.env.REACT_APP_FILE_BASE_URL}/${user.file}`} />}
                                    <div value={logo} onChange={(event) => {
                                        const logo = event.target?.files[0];
                                        setLogo(logo);
                                    }} style={{ border: "1px solid #101010" }} className=" mt-4 p-3 form-one__control form-one__control--full">
                                        <div>
                                            <label className="fw-bold">Company Logo</label>
                                        </div>
                                        <input type="file" className=" mt-2" name="companyLogo" placeholder="Company Logo" />
                                    </div>
                                    <div className="form-one__control form-one__control--full mt-3">
                                        <button onClick={(event) => {
                                            event.preventDefault();
                                            uploadLogo();
                                        }} type="submit" className="hiredots-btn">
                                            <span>Upload</span>
                                        </button>
                                    </div>
                                    {user.file && <div className="text-center pt-5">
                                        <button className="hiredots-btn p-5 w-100"
                                            onClick={() => {
                                                navigate("/add-job");
                                            }}
                                        > <h4 className="text-white">You can start uploading Jobs here</h4></button>
                                    </div>}
                                </>
                            }
                        </> : <h3 className="text-center my-5">Unauthorized</h3>}
                    </div>
                </section >
                <Footer />
            </div >

        </>
    )
}

export default Main