import React from 'react';
import { Link } from 'react-router-dom';
import Bgimg from '../../assets/images/shapes/service-bg-1.jpg';
import Service1 from '../../assets/images/services/job-search.png';
import Service2 from '../../assets/images/services/background-checks.png';
import Service3 from '../../assets/images/services/aptitute-test.png';
import sanitize from 'sanitize-html';

export const services = [
    {
        image: Service1,
        icon: "icon-consulting",
        category: "Services",
        title: "Job Search",
        link: "/jobs",
        text: "Discover your dream job with us. Let's make your career aspirations a reality."
    },
    {
        image: Service2,
        icon: "icon-controller",
        category: "Services",
        title: "Background Checks",
        link: "/background-checks",
        text: "Perform background checks on prospective hires"
    },
    {
        image: Service3,
        icon: "icon-policy",
        category: "Services",
        title: "Employer of Record",
        link: "/employer-of-record",
        text: "Streamline your global hiring with our Employer of Record service."
    }
];

function Service({ data }) {
    return (
        <div>
            {data && <section className="service-one" style={{ backgroundImage: `url(${Bgimg})` }}>
                <div className="container">
                    <div className="sec-title text-center">
                        <h6 className="sec-title__tagline">Our Service area</h6>
                        <h3 dangerouslySetInnerHTML={{ __html: sanitize(data.ServiceArea.heading) }} className="sec-title__title">
                        </h3>
                    </div>
                    <div className="row gutter-y-30">
                        {data.ServiceArea.ServiceAreaItems.map((service, index) => (
                            <div key={index} className="col-md-6 col-lg-4">
                                <div className="service-one-card service-card-two" data-wow-duration="1500ms" data-wow-delay={`${index}ms`}>
                                    <div className="service-one-card__image">
                                        <img src={`${process.env.REACT_APP_CMS_BASE_API}${service.image.url}`} alt={service.title} />
                                    </div>
                                    <div className="service-one-card__content">
                                        <div className="service-one-card__icon">
                                            <i className={service.icon} />
                                            {/* <Link to="/services" className="service-one-card__cat">
                                                {service.category}
                                            </Link> */}
                                        </div>
                                        <h3 className="service-one-card__title">
                                            <Link to={service.link}>{service.title}</Link>
                                        </h3>
                                        <p className="service-one-card__text">
                                            {service.description}
                                        </p>
                                    </div>
                                    <Link to={service.link} className="service-one-card__link">
                                        Read More
                                        <i className="icon-right-arrow" />
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>}
        </div>
    );
}

export default Service;
