import React, { useEffect, useState } from 'react';
import { getJobCategories } from '../../api.controller';
import { Link } from 'react-router-dom';
import './JobCategoriesSection.css';


function Feature() {
    const [categories, setCategories] = useState([]);
    const _getJobCategories = async () => {
        try {
            const response = await getJobCategories();
            console.log(response);
            setCategories(response?.data?.docs || []);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        _getJobCategories();
    }, []);
    return (
        <div>
            <section className="feature-one pt-0">
                <div className="container">
                    <h3>Browse Jobs By Category</h3>
                    <div className="row mt-5">
                        {categories.map(category => {
                            return <div className="col-lg-3 col-md-6">
                                <Link to={`/jobs?category=${category._id ?? "Others"}`}>
                                    <div className=" category  p-2  w-100  mb-2">
                                        <h6>{category._id ?? "Others"}</h6>
                                        <div style={{ position: "relative" }}>
                                            <hr />
                                            <h6 style={{ position: "absolute", right: 30, bottom: -20, borderRadius: "50%", background: "#efefef", height: 30 }} className="fw-light text-center px-3 pt-1 fw-bold text-dark">{category.jobCount}</h6>
                                        </div>

                                    </div>
                                </Link>
                            </div>
                        })}
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Feature;
