import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import Slidershape2 from '../../assets/images/shapes/slider-1-shape-2.png'
import Bgimg from '../../assets/images/backgrounds/hero.jpg'
import Bgimg1 from '../../assets/images/shapes/slider-1-shape-1.png'
import Bgimg2 from '../../assets/images/backgrounds/slider-1-2.jpg'
import Bgimg3 from '../../assets/images/backgrounds/slider-1-3.jpg'
import sanitizeHtml from 'sanitize-html';

import { getStrapiData } from '../../api.controller'
import YesImage from '../../assets/images/yes.png'


const Homeslider = {
    loop: true,
    dots: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 9000,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                speed: 1000
            }
        },
        {
            breakpoint: 600,
            settings: {
                speed: 500
            }
        },
    ],

}

function Sliderone() {

    const [carouselItems, setCarouselItems] = useState();
    const sliderRef = useRef();
    useEffect(() => {
        getStrapiData("carousels").then(response => {
            console.log(response);
            setCarouselItems(response.data)

            const intervalHandle = setInterval(() => {
                if (sliderRef.current) {
                    sliderRef.current.slickPlay();
                    clearInterval(intervalHandle);
                }
            }, 1000)
        }).catch(err => {
            console.log(err);
        });
    }, []);

    return (
        <div>
            {carouselItems && <section className="main-slider-one">
                <Slider ref={sliderRef} className='' {...Homeslider} slidesToShow={1} slidesToScroll={1} arrows={false}>
                    {carouselItems.map((item, index) => {
                        console.log(`${process.env.REACT_APP_CMS_BASE_API}${item.data.picture.url}`);
                        const bgColor = item.data.backgroundColor;
                        return <div key={index}>
                            <div className="item" data-dot={`<button>${index + 1}/</button>`}>
                                <div className="main-slider-one__item " style={{ height: "90vh" }}>
                                    <div className="position-absolute top-0 start-0 end-0 bottom-0 " style={{
                                        backgroundImage: `url(${process.env.REACT_APP_CMS_BASE_API}${item.data.picture.formats.large.url})`, backgroundColor: `${bgColor}`,
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center center',
                                    }} />
                                    <div className="main-slider-one__overlay" />
                                    <div className="main-slider-one__shape" style={{ backgroundImage: `url(${Bgimg1})` }} />
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="main-slider-one__content">
                                                    <div class="main-slider-one__image">
                                                        <img src={YesImage} alt="hiredots" />
                                                    </div>
                                                    <h2 dangerouslySetInnerHTML={{ __html: sanitizeHtml(item.data.headline) }} className="main-slider-one__title">
                                                    </h2>
                                                    <div className="main-slider-one__btn">
                                                        <Link to="/services" className="hiredots-btn">
                                                            <span>Discover More</span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}

                </Slider>
            </section>}
        </div>
    )
}

export default Sliderone