import React, { useState } from 'react'
import Header from '../Header/Main'
import HeaderThree from '../HeaderThree/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Servicepage from '../Menservice/Servicepage'
import Footer from '../Footer/Main'
import { Link, useNavigate } from 'react-router-dom'
import Logo from '../../assets/images/logo-dark.png'
import { login } from '../../api.controller'

function Main() {

    const [formData, setFormData] = useState({ email: "", password: "" });
    const navigate = useNavigate();

    const loginUser = async () => {
        const keys = Object.keys(formData);
        for (const key of keys) {
            if (!formData[key]) {
                alert(`${key} is required!`);
                return;
            }
        }

        try {
            const data = await login(formData);
            console.log(data);
            localStorage.setItem("userData", JSON.stringify({ ...data?.data?.user }));
            navigate("/");
        } catch (error) {
            alert(error?.message);
        }
    }



    return (
        <>
            <div className="page-wrapper">

                {/* <Breadcrumb short subtitle="Home" title="Login" /> */}
                <section className="service-one service-one--page pt-0">

                    <div className="container">
                        <Link className="d-flex justify-content-center" to="/">
                            <img className=" d-inline-block m-5" src={Logo} alt="Hiredots HTML" width={125} />
                        </Link>
                        <h4 className="text-center mb-5">Welcome back! Please log in to continue.</h4>
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="">
                                    <form className="contact-one__form contact-form-validated form-one background-base wow fadeInUp" data-wow-duration="1500ms" action="inc/sendemail.php">
                                        <div className="form-one__group">
                                            <div className="form-one__control form-one__control--full">
                                                <input
                                                    value={formData.email}
                                                    onChange={(event) => {
                                                        const email = event.target.value;
                                                        setFormData({ ...formData, email });
                                                    }} type="email" name="email" placeholder="Email address" />
                                            </div>
                                            <div className="form-one__control form-one__control--full">
                                                <input
                                                    value={formData.password}
                                                    onChange={(event) => {
                                                        const password = event.target.value;
                                                        setFormData({ ...formData, password });
                                                    }}
                                                    type="password" name="password" placeholder="Password" />
                                            </div>

                                            <div className="form-one__control form-one__control--full">
                                                <button onClick={(event) => {
                                                    event.preventDefault();
                                                    loginUser();
                                                }} type="submit" className="hiredots-btn hiredots-btn--white">
                                                    <span>Login</span>
                                                </button>
                                                <div className="text-white mt-3">
                                                    Don’t have an account? <Link to="/register" >Register</Link> now.
                                                </div>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>

        </>
    )
}

export default Main