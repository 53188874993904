import React, { useEffect, useState } from 'react'
import Header from '../Header/Main'
import HeaderThree from '../HeaderThree/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Servicepage from '../Menservice/Servicepage'
import Footer from '../Footer/Main'
import { Link, useNavigate } from 'react-router-dom'
import Logo from '../../assets/images/logo-dark.png'
import { register } from '../../api.controller'
import './Main.css'

function Main() {
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        fullName: "",
        userType: "JOB_SEEKER",
        email: "",
        password: "",
        confirmPassword: ""
    });

    useEffect(() => {
        console.log("User Type has changed");
        console.log(formData.userType);
    }, [formData.userType]);

    useEffect(() => {
        console.log(formData);
    }, [formData]);

    const registerUser = async () => {
        // console.log("files", formData);
        // return;
        try {
            const keys = Object.keys(formData);
            for (const key of keys) {
                if (!formData[key] && ((formData?.userType == "JOB_SEEKER" && key == "cvFile") || (formData?.userType == "COMPANY" && key == "companyLogo"))) {
                    alert(`${key} is required`);
                    return;
                }
            }

            if (formData.confirmPassword != formData.password) {
                alert("Passwords do not match");
                return;
            }

            let payload = { ...formData };
            if (formData?.userType == "JOB_SEEKER") {
                payload = { ...payload, file: formData?.cvFile };
                delete payload.companyLogo;
                delete payload.cvFile;
            } else {
                payload = { ...payload, file: formData?.companyLogo };
                delete payload.companyLogo;
                delete payload.cvFile;
            }
            const data = await register(payload);
            alert("You've been successfully registered. You will be redirected to login");
            navigate("/login");
            console.log(data);
        } catch (error) {
            alert(error?.message);
        }
    }

    return (
        <>
            <div className="page-wrapper">

                {/* <Breadcrumb short subtitle="Home" title="Login" /> */}
                <section className="service-one service-one--page pt-0">

                    <div className="container">
                        <Link className="d-flex justify-content-center" to="/">
                            <img className=" d-inline-block m-5" src={Logo} alt="Hiredots HTML" width={125} />
                        </Link>
                        <h4 className="text-center mb-5">Join our community! Sign up to get started.</h4>
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="">
                                    <form className="contact-one__form contact-form-validated form-one background-base wow fadeInUp" data-wow-duration="1500ms" >
                                        <div className="form-one__group">
                                            <div value={formData.userType} onChange={(event) => {
                                                const userType = event.target.value;
                                                setFormData((currentValue) => ({ ...currentValue, userType }))
                                            }} className="form-one__control form-one__control--full">
                                                <select style={{ border: "1px solid rgba(var(--hiredots-white-rgb, 255, 255, 255), 0.15)" }} className='bg-transparent text-white form-one__control form-one__control--full' name="userType" >
                                                    <option value="JOB_SEEKER">I am a Job Seeker</option>
                                                    <option value="COMPANY">I am a Company</option>
                                                </select>
                                            </div>
                                            <div className="form-one__control form-one__control--full">
                                                <input value={formData.fullName} onChange={(event) => {
                                                    const fullName = event.target.value;
                                                    setFormData({ ...formData, fullName })
                                                }} type="text" name="name" placeholder={formData?.userType == "COMPANY" ? "Company Name" : "Full Name"} />
                                            </div>
                                            <div value={formData.email} onChange={(event) => {
                                                const email = event.target.value;
                                                setFormData({ ...formData, email })
                                            }} className="form-one__control form-one__control--full">
                                                <input type="email" name="email" placeholder="Email address" />
                                            </div>
                                            <div value={formData.password} onChange={(event) => {
                                                const password = event.target.value;
                                                setFormData({ ...formData, password })
                                            }} className="form-one__control form-one__control--full">
                                                <input type="password" name="password" placeholder="Password" />
                                            </div>
                                            <div value={formData.confirmPassword} onChange={(event) => {
                                                const confirmPassword = event.target.value;
                                                setFormData({ ...formData, confirmPassword })
                                            }} className="form-one__control form-one__control--full">
                                                <input type="password" name="password" placeholder="Confirm Password" />
                                            </div>

                                            {formData.userType == "COMPANY" && <div value={formData.companyLogo} onChange={(event) => {
                                                const companyLogo = event.target?.files[0];
                                                setFormData({ ...formData, companyLogo })

                                            }} style={{ border: "1px solid rgba(var(--hiredots-white-rgb, 255, 255, 255), 0.15)" }} className=" p-3 form-one__control form-one__control--full">
                                                <label className="text-white fw-bold">Company Logo</label>
                                                <input type="file" className="text-white mt-2" name="companyLogo" placeholder="Company Logo" />
                                            </div>}
                                            {formData.userType == "JOB_SEEKER" && <div value={formData.cvFile} onChange={(event) => {
                                                const cvFile = event.target?.files[0];
                                                setFormData({ ...formData, cvFile })

                                            }} style={{ border: "1px solid rgba(var(--hiredots-white-rgb, 255, 255, 255), 0.15)" }} className=" p-3 form-one__control form-one__control--full">
                                                <div>
                                                    <label className="text-white fw-bold">CV File</label>
                                                </div>
                                                <input type="file" className="text-white mt-2" name="companyLogo" placeholder="Company Logo" />
                                            </div>}

                                            <div className="form-one__control form-one__control--full">
                                                <button onClick={(event) => {
                                                    event.preventDefault();
                                                    registerUser();
                                                }} type="submit" className="hiredots-btn hiredots-btn--white">
                                                    <span>Sign Up</span>
                                                </button>
                                                <div className="text-white mt-3">
                                                    Already have an account? <Link to="/login" >Login</Link> now.
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>

        </>
    )
}

export default Main