import React, { useEffect, useState } from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Blogpage from '../Menblog/Blogpage'
import Footer from '../Footer/Main'
import CareerListingPage from '../MenCareerListings/CareerListingPage'
import { getStrapiData } from '../../api.controller'

function Main() {
    const [data, setData] = useState();
    useEffect(() => {
        getStrapiData("career-listing-page").then(response => {
            console.log(response);
            setData(response.data);
        });
    }, []);


    return (
        <>
            <div className="page-wrapper">
                <Header />
                <Breadcrumb data={data} subtitle="Home" title="Career Listing" />
                <CareerListingPage />
                <Footer />
            </div>
        </>
    )
}

export default Main