import React from 'react';
import { Link } from 'react-router-dom';
import feature1 from '../../assets/images/resources/feature-1-1.jpg';
import feature2 from '../../assets/images/resources/feature-1-2.jpg';
import feature3 from '../../assets/images/resources/feature-1-3.jpg';

const features = [
    {
        img: feature1,
        iconClass: 'icon-solution',
        title: 'Recruitement',
        text: 'We take care of Pre Screening, Assessment, Pre-Interview, Background Screening.',
        route: '/recruitment'
    },
    {
        img: feature2,
        iconClass: 'icon-creative-idea',
        title: 'Payroll Outsourcing',
        text: 'We will take up the task of salary and payroll matters, so that your company can concentrate on the task at hand',
        route: '/payroll-outsourcing'
    },
    {
        img: feature3,
        iconClass: 'icon-mission',
        title: 'Executive Search',
        text: 'Matching talent to opportunity meticulously, safeguarding integrity and excellence in every placement.',
        route: '/executive-search'
    }
];

function Feature({ data }) {
    return (
        <div>
            <section className="feature-one">
                <div className="container">
                    <div className="row gutter-y-30">
                        {data && data.FeatureItems.map((feature, index) => (
                            <div key={index} className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay={`${100 * (index + 1)}ms`}>
                                <div className="feature-one__item text-center">
                                    <div className="feature-one__item__img">
                                        <img src={`${process.env.REACT_APP_CMS_BASE_API}${feature.image.url}`} alt="hiredots" />
                                    </div>
                                    <div className="feature-one__item__content">
                                        <div className="feature-one__item__ball" />
                                        <div className="feature-one__item__icon">
                                            <span className={feature.icon} />
                                        </div>
                                        <h3 className="feature-one__item__title">
                                            {feature.title}
                                        </h3>
                                        <p className="feature-one__item__text">
                                            {feature.description}
                                        </p>
                                        <Link to={feature.link} className="hiredots-btn">
                                            <span>{feature.buttonDescription}</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Feature;
