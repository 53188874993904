import React, { useEffect, useState } from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Servicepage from '../Menservice/Servicepage'
import Footer from '../Footer/Main'
import { getAdmins, getStrapiData, getUsers } from '../../api.controller'
import { Link, useNavigate } from 'react-router-dom'

function Main() {
    const [data, setData] = useState();
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState();
    const [admins, setAdmins] = useState([]);
    const [page, setPage] = useState(0);
    const [pages] = useState([
        { label: "Users", value: 0 },
        { label: "Admins", value: 1 }
    ]);

    const navigate = useNavigate();
    useEffect(() => {
        getStrapiData("executive-search-page").then(response => {
            setData(response.data);
        }).catch(err => {
            console.log(err);
        });

        const stringifiedUser = localStorage.getItem("userData");
        if (stringifiedUser) {
            const _user = JSON.parse(stringifiedUser);
            setUser(_user);
        }
    }, []);

    useEffect(() => {
        if (page == 0) {
            _getUsers();
        } else if (page == 1) {
            _getAdmins();
        }
    }, [page]);
    const _getUsers = async () => {
        const response = await getUsers();
        setUsers(response?.data?.data || []);
    }
    const _getAdmins = async () => {
        const response = await getAdmins();
        setAdmins(response?.data?.data || []);
    }

    return (
        <>
            <div className="page-wrapper">
                <Header />
                <Breadcrumb data={data} subtitle="Home" title="User Management" />
                <section className="service-one service-one--page">
                    {user && user.userType.toUpperCase() == "ADMIN" ? <div className="container">
                        <div className="row mb-60">
                            <div className="d-flex flex-wrap">
                                {pages.map(_page => (<div onClick={() => {
                                    setPage(_page.value);
                                }} className={page == _page.value ? `chip-btn p-2 px-4 me-2 mb-3 fw-bold rounded-5 selected` : `chip-btn p-2 px-4 me-2 mb-3 fw-bold rounded-5 `}>
                                    {_page.label}
                                </div>))}
                            </div>
                        </div>
                        {page == 0 && <table className="table">
                            <thead>
                                <th>Name</th>
                                <th>User Type</th>
                                <th>Associated File</th>
                                <th>Date Created</th>
                                <th>Date Modified</th>
                            </thead>
                            <tbody>
                                {users.map((user) => {
                                    return <tr>
                                        <td>{user.fullName}</td>
                                        <td>
                                            {user.userType.includes("SEEKER") && <span className="badge bg-dark">{user.userType.replaceAll("_", " ")}</span>}
                                            {user.userType.includes("ADMIN") && <span className="badge bg-primary">{user.userType.replaceAll("_", " ")}</span>}
                                            {user.userType.includes("COMPANY") && <span className="badge bg-success">{user.userType.replaceAll("_", " ")}</span>}
                                        </td>
                                        <td>{user.file ? <Link target="_blank" to={`${process.env.REACT_APP_FILE_BASE_URL}/${user.file}`}>{user.file}</Link> : "N/A"} </td>
                                        <td>{user.createdAt}</td>
                                        <td>{user.updatedAt}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>}
                        {page == 1 && <>
                            <div className="d-flex justify-content-end">
                                <button className='hiredots-btn hiredots-btn--base mb-5'
                                    onClick={() => {
                                        navigate("/add-admin");
                                    }}
                                >Add New Admin</button>
                            </div>
                            <table className="table">
                                <thead>
                                    <th>Name</th>
                                    <th>Date Created</th>
                                    <th>Date Modified</th>
                                </thead>
                                <tbody>
                                    {admins.map((user) => {
                                        return <tr>
                                            <td>{user.fullName}</td>
                                            <td>{user.createdAt}</td>
                                            <td>{user.updatedAt}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </>
                        }
                    </div> : <h3 className="text-center my-5">Unauthorized</h3>}
                </section >
                <Footer />
            </div >

        </>
    )
}

export default Main