import React, { useEffect, useState } from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Jobfrom from '../Menjobs/Jobfrom'
import Footer from '../Footer/Main'
import { getStrapiData } from '../../api.controller'

function Main() {
  const [data, setData] = useState();
  useEffect(() => {
    getStrapiData("job-page").then(response => {
      console.log(response);
      setData(response.data);
    });
  }, []);
  return (
    <>
      <div className="page-wrapper">
        <Header />
        <Breadcrumb data={data} subtitle="Home" title="Jobs" />
        <Jobfrom />
        <Footer />
      </div>
    </>
  )
}

export default Main