import React, { useEffect, useState } from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Servicepage from '../Menservice/Servicepage'
import Footer from '../Footer/Main'
import Jica from '../../assets/images/resources/jica.png'
import Expro from '../../assets/images/resources/expro.png'
import Nespresso from '../../assets/images/resources/nespresso.png'
import Dow from '../../assets/images/resources/dow.png'
import Shimzu from '../../assets/images/resources/shimzu.png'
import DubaiChamber from '../../assets/images/resources/dubai-chamber.png'
import Brussels from '../../assets/images/resources/brussels.png'
import WoolWorths from '../../assets/images/resources/woolworths.png'
import BestWestern from '../../assets/images/resources/best-western.png'
import MonuRent from '../../assets/images/resources/monurent.png'
import DHL from '../../assets/images/resources/dhl.png'
import DRA from '../../assets/images/resources/dra.png'
import Landcaster from '../../assets/images/resources/landcaster.png'
import EnterpriseLife from '../../assets/images/resources/enterprise-life.png'
import EnsAfrica from '../../assets/images/resources/ensafrica.svg'
import Metropolitan from '../../assets/images/resources/metropolitan-logo.png'
import EdCon from '../../assets/images/resources/edcon-logo.png'
import Edgars from '../../assets/images/resources/edgars.png'
import Jet from '../../assets/images/resources/jet-logo.png'
import Dufry from '../../assets/images/resources/dufry-logo.png'
import Daba from '../../assets/images/resources/daba-logo.png'
import Game from '../../assets/images/resources/game.png'
import TheRoyalSenchi from '../../assets/images/resources/royal-senchi.png'
import FiestaRoyale from '../../assets/images/resources/fiesta-royale.png'
import RonsardEcole from '../../assets/images/resources/ronsard.jpeg'
import Cambridge from '../../assets/images/resources/cambridge.png'
import RosardCambridge from '../../assets/images/resources/rosard-cambridge.png'
import NYU from '../../assets/images/resources/nyu.png'
import NyuCted from '../../assets/images/resources/nyu-cted.png'
import Wellness from '../../assets/images/resources/wellness.png'
import Hurrenhut from '../../assets/images/resources/herrnhut.png'
import Amaja from '../../assets/images/resources/amaja.png'
import Santova from '../../assets/images/resources/santova.png'
import Necotrans from '../../assets/images/resources/necotrans.png'
import SavinoDelBene from '../../assets/images/resources/salvino.png'
import ApmTerminals from '../../assets/images/resources/apm.jpeg'
import AmarisTerminalLtd from '../../assets/images/resources/amaris.png'
import Hazmat from '../../assets/images/resources/hazmat.jpeg'
import EntecInternational from '../../assets/images/resources/entech.png'
import TechMahindra from '../../assets/images/resources/tech-mahindra.png'
import FairGreenLtd from '../../assets/images/resources/fair-green-ltd.png'
import NetConcepts from '../../assets/images/resources/netconcepts.png'
import Servair from '../../assets/images/resources/servair.png'
import Jaguar from '../../assets/images/resources/jaguar.png'
import Itochu from '../../assets/images/resources/itochu.webp'
import AlphaKakra from '../../assets/images/resources/alphakakra.png'
import Emel from '../../assets/images/resources/emel.jpeg'
import AvonGroup from '../../assets/images/resources/avon-group.png'
import Fanmilk from '../../assets/images/resources/fanmilk.png'
import ChiropracticWellnessCenter from '../../assets/images/resources/chiropractic-wellness-center.png'
import Lupp from '../../assets/images/resources/lupp.png'
import { getStrapiData } from '../../api.controller'


function Main() {

    const [data, setData] = useState();

    useEffect(() => {
        getStrapiData("our-clients-page").then(response => {
            console.log(response);
            setData(response.data);
        });
    }, []);


    const clientIcons = {
        gov_legal: {
            title: "Government / Legal",
            data: [
                Jica,
                DubaiChamber,
                EnsAfrica
            ]
        },
        financial: {
            title: "Financial Services",
            data: [
                EnterpriseLife,
                Metropolitan
            ]
        },
        retail: {
            title: "Retail",
            data: [
                EdCon,
                Edgars,
                Jet,
                WoolWorths,
                Dufry,
                Daba,
                Game
            ]
        },
        hospitality:
        {
            title: "Hospitality",
            data: [
                BestWestern,
                TheRoyalSenchi,
                FiestaRoyale
            ]
        },
        educational: {
            title: "Educational",
            data: [
                Landcaster,
                // RonsardEcole,
                RosardCambridge,
                NyuCted,
                Wellness
            ]
        },
        mogc: {
            title: "Mining/Oil & Gas/Construction",
            data: [
                MonuRent,
                DRA,
                Hurrenhut,
                Expro,
                Amaja,
                Shimzu
            ]
        },
        logistics: {
            title: "Logistics",
            data: [
                Santova,
                Necotrans,
                SavinoDelBene,
                ApmTerminals,
                AmarisTerminalLtd,
                Hazmat,
                EntecInternational
            ]
        },
        technology: {
            title: "Information Technolofy",
            data: [
                TechMahindra,
                FairGreenLtd,
                NetConcepts
            ]
        },
        aviation: {
            title: "Aviation",
            data: [
                Brussels,
                Servair
            ]
        },
        trading:
        {
            title: "Trading/FMCG",
            data: [
                Jaguar,
                Itochu,
                AlphaKakra,
                Emel,
                AvonGroup
            ]
        },
        others: {
            title: "Other Industries",
            data: [
                SavinoDelBene,
                Fanmilk,
                ChiropracticWellnessCenter,
                Lupp
            ]
        }
    }
    return (
        <>
            <div className="page-wrapper">
                <Header />
                <Breadcrumb data={data} subtitle="Home" title="Our Clients" />
                <section className="service-one service-one--page">
                    <div className="container">

                        <h3 className="sec-title__title mb-5">
                            You are in good company. <br />A lot of popular brands trust us
                        </h3>

                        {Object.keys(clientIcons).map(key => (
                            <div className="row gutter-y-30 mt-30 mb-60">
                                <h3 style={{ fontSize: 22 }} className="mt-30 mb-0">{clientIcons[key].title}</h3>
                                <hr style={{ marginTop: 10 }} />
                                {
                                    clientIcons[key].data.map((icon) => (
                                        <div className="col-md-4 col-lg-3 ">
                                            <div className="d-flex align-center h-100">
                                                <img style={{ width: "100%", maxHeight: 130, objectFit: "contain" }} src={icon} />
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        ))
                        }


                    </div>
                </section >
                <Footer />
            </div >

        </>
    )
}

export default Main