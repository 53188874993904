import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Bgimg from '../../assets/images/backgrounds/footer-bg.jpg'
import Logo from '../../assets/images/logo-light.png'
import Footeravtar from '../../assets/images/resources/footer-about-avata.png'
import { getStrapiData } from '../../api.controller'

function Main() {
    const [socialMediaLinks, setSocialMediaLinks] = useState();
    useEffect(() => {
        getStrapiData("social-media-links").then(response => {
            setSocialMediaLinks(response.data);
        }).catch(err => {
            console.log(err);
        })
    }, []);

    return (
        <div>
            <footer className="main-footer background-black">
                <div className="main-footer__bg" style={{ backgroundImage: `url(${Bgimg})` }} />
                <div className="main-footer__subscribe">
                    <div className="container">
                        <div className="main-footer__subscribe__wrapper">
                            <div className="main-footer__subscribe__left">
                                <span className="main-footer__subscribe__icon">
                                    <i className="icon-message" />
                                </span>
                                <h3 className="main-footer__subscribe__title">
                                    Subscribe Now to Get <br />
                                    Latest Updates
                                </h3>
                            </div>
                            <div className="main-footer__subscribe__right">
                                <form action="#" data-url="MAILCHIMP_FORM_URL" className="main-footer__newsletter mc-form">
                                    <input type="text" name="EMAIL" placeholder="Email address" />
                                    <button type="submit" className="fas fa-paper-plane">
                                        <span className="sr-only">submit</span>
                                    </button>
                                </form>
                                <div className="mc-form__response" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-footer__top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-xl-2">
                                <div className="footer-widget footer-widget--about">
                                    <div className="footer-widget footer-widget--contact">
                                        <h2 className="footer-widget__title">Careers in Ghana Partners</h2>
                                        <ul className="list-unstyled footer-widget__links">
                                            <li>
                                                <Link to="https://www.careersinafrica.com/en/">Careers In Africa</Link>
                                            </li>
                                            <li>
                                                <Link to="https://www.jobnetafrica.com/">Job Net Africca</Link>
                                            </li>
                                            <li>
                                                <Link to="https://www.tcconline.eu/360-feedback/">TCC online</Link>
                                            </li>
                                            <li>
                                                <Link to="https://www.hiretheright.com/pages/psychodiagnostics/">TCC tests</Link>
                                            </li>
                                            {/* <li>
                                            <Link to="/history">Executive Search</Link>
                                        </li>
                                        <li>
                                            <Link to="/about">Our Solutions</Link>
                                        </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-xl-2">
                                <div className="footer-widget footer-widget--contact">
                                    <h2 className="footer-widget__title">Links</h2>
                                    <ul className="list-unstyled footer-widget__links">
                                        <li>
                                            <Link to="/background-checks">Background Checks</Link>
                                        </li>
                                        <li>
                                            <Link to="/https://www.accurateskills.com/">Aptitude Test</Link>
                                        </li>
                                        <li>
                                            <Link to="/contact">Contact</Link>
                                        </li>
                                        {/* <li>
                                            <Link to="/history">Executive Search</Link>
                                        </li>
                                        <li>
                                            <Link to="/about">Our Solutions</Link>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-4">
                                <div className="footer-widget footer-widget--time">
                                    <h2 className="footer-widget__title">Address</h2>
                                    <p className="footer-widget__text">
                                        VeroStina House, 2nd Floor, Off Spintex Road, <br />Comm 18 (Opp DSTV office, Near Comm 18 Police Station) Spintex Road - Accra Ghana
                                    </p>

                                </div>
                            </div>
                            <div className="col-md-6 col-xl-3">
                                <h2 className="footer-widget__title">Contact (Job Seeker)</h2>

                                <ul className="list-unstyled footer-widget__info">
                                    <li style={{ minWidth: 320 }}>
                                        <i className="fas fa-envelope" />{" "}
                                        <Link to="mailto:customerservice@careersinghana.com">customerservice@careersinghana.com</Link>
                                    </li>
                                    <li>
                                        <i className="fas fa-phone-square" />{" "}
                                        <Link to="tel:+233303934184">(+233)303934184</Link>
                                    </li>
                                    <li>
                                        <i className="fas fa-phone-square" />{" "}
                                        <Link to="tel:+233206964597">(+233)206964597</Link>
                                    </li>
                                    <li>
                                        <i className="fab fa-whatsapp" />{" "}
                                        <Link to="tel:+233206964597">(+233)206964597</Link>
                                    </li>
                                </ul>

                                <h2 className="footer-widget__title">Contact (Employers)</h2>

                                <ul className="list-unstyled footer-widget__info">
                                    <li >
                                        <i className="fas fa-envelope" />{" "}
                                        <Link to="mailto:employers@careersinghana.com">employers@careersinghana.com</Link>
                                    </li>
                                    <li>
                                        <i className="fas fa-phone-square" />{" "}
                                        <Link to="tel:+233303934184">(+233)303934184</Link>
                                    </li>
                                    <li>
                                        <i className="fas fa-phone-square" />{" "}
                                        <Link to="tel:+233500043033">(+233)500043033</Link>
                                    </li>
                                    <li>
                                        <i className="fas fa-phone-square" />{" "}
                                        <Link to="tel:+233500865676">(+233)500865676</Link>
                                    </li>
                                    <li>
                                        <i className="fab fa-whatsapp" />{" "}
                                        <Link to="tel:+233500043033">(+233)500043033</Link>
                                    </li>
                                </ul>


                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-footer__bottom">
                    <div className="container">
                        <div className="main-footer__bottom__inner">
                            {socialMediaLinks && <div className="footer-widget__social">
                                {socialMediaLinks.twitter && <Link to={socialMediaLinks.twitter}>
                                    <i className="fab fa-twitter" aria-hidden="true" />
                                    <span className="sr-only">Twitter</span>
                                </Link>}
                                {socialMediaLinks.facebook && <Link to={socialMediaLinks.facebook}>
                                    <i className="fab fa-facebook" aria-hidden="true" />
                                    <span className="sr-only">Facebook</span>
                                </Link>}

                                {socialMediaLinks.instagram && <Link to={socialMediaLinks.instagram}>
                                    <i className="fab fa-instagram" aria-hidden="true" />
                                    <span className="sr-only">Instagram</span>
                                </Link>}
                                {socialMediaLinks.youtube && <Link to={socialMediaLinks.youtube}>
                                    <i className="fab fa-youtube" aria-hidden="true" />
                                    <span className="sr-only">Youtube</span>
                                </Link>}
                            </div>}
                            <p className="main-footer__copyright">
                                © Copyright <span className="dynamic-year" /> by Careers In Ghana
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Main