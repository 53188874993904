import Swal, { SweetAlertIcon } from 'sweetalert2';


export const showLoading = (title: string = "Please wait", message: string = "Processing Data") => {
    Swal.fire({
        title: title,
        html: message,
        didOpen: () => {
            Swal.showLoading();
        }
    });
}



export const showNotification = (title: string = "Notification", message: string = "", icon: SweetAlertIcon = "info") => {
    Swal.fire({
        title,
        text: message,
        icon
    });
}

export const capitalize = (str) => {
    if (!str) return str; // handle empty or undefined strings
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}