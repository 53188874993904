import React, { useEffect, useState } from 'react'
import Header from '../Header/Main'
import JobCategoriesSection from '../Menhomeone/JobCategoriesSection'
import Sliderone from '../Menhomeone/Sliderone'
import Feature from '../Menhomeone/Feature'
import About from '../Menhomeone/About'
import Funfact from '../Menhomeone/Funfact'
import Service from '../Menhomeone/Service'
import Cta from '../Menhomeone/Cta'
import Client from '../Menhomeone/Client'
import Projectone from '../Menhomeone/Projectone'
import Ctatwo from '../Menhomeone/Ctatwo'
import Testimonial from '../Menhomeone/Testimonial'
import Blog from '../Menhomeone/Blog'
import Footer from '../Footer/Main'
import { getStrapiData } from '../../api.controller'

function Main() {
    const [data, setData] = useState();
    useEffect(() => {
        getStrapiData("home-page").then(response => {
            console.log(response);
            setData(response.data)
        }).catch(err => {
            console.log(err);
        });
    }, []);
    return (
        <>
            <div className="page-wrapper">
                <Header />
                <Sliderone />
                <Feature data={data} />
                <About data={data} />
                <JobCategoriesSection />
                <Funfact data={data} />
                <Service data={data} />
                <Cta data={data} />
                <Client data={data} />
                {/* <Projectone /> */}
                {/* <Ctatwo /> */}
                {/* <Testimonial /> */}
                {/* <Blog /> */}
                <Footer />
            </div>
        </>
    )
}

export default Main