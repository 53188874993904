import React, { useEffect, useRef } from 'react'
import Slider from 'react-slick'
import sanitize from 'sanitize-html'


const Clientslider = {
    items: 5,
    margin: 65,
    smartspeed: 1000,
    loop: true,
    autoplay: true,
    arrows: false,
    responsive: [
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                margin: 30,
            },
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                margin: 30,
            },
        },
    ],
}

function Client({ hideTitle = false, data }) {
    const sliderRef = useRef();
    useEffect(() => {
        const intervalHandle = setInterval(() => {
            if (sliderRef.current) {
                sliderRef.current.slickPlay();
                clearInterval(intervalHandle);
            }
        }, 1000)
    }, []);


    return (
        <div>
            <div className="client-carousel ">
                <div className="container">
                    <div className="row">
                        {!hideTitle && <div className="col-xl-3">
                            {data && <h3 dangerouslySetInnerHTML={{ __html: sanitize(data.ClientSection.description) }} className="client-carousel__title">
                            </h3>}
                        </div>}
                        <div className={!hideTitle ? "col-xl-9" : "col-xl-12"}>
                            <Slider ref={sliderRef} className='client-one'{...Clientslider} slidesToShow={5} slidesToScroll={1} autoplay={true} autoplaySpeed={1000}>
                                {data && data.ClientSection.Logos.map(logo => {
                                    return <div className="client-carousel__one__item px-3 "><img style={{ maxHeight: 100 }} src={`${process.env.REACT_APP_CMS_BASE_API}${logo.url}`} /></div>
                                })}
                            </Slider>
                        </div>
                    </div>
                    <div className="client-carousel__border" />
                </div>
            </div>
        </div>
    )
}

export default Client