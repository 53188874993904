import React from 'react'
import { Route, Routes, } from 'react-router-dom';
import Homeone from '../components/Homeone/Main';
import About from '../components/About/Main';
import Jobs from '../components/Jobs/Main'
import Services from '../components/Services/Main'
import Blog from '../components/Blog/Main'
import CareerListing from '../components/CareerListing/Main'
import BlogDetails from '../components/BlogDetails/Main'
import Contact from '../components/Contact/Main';
import ExecutiveSearch from '../components/ExecutiveSearch/Main';
import Recruitment from '../components/Recruitment/Main';
import PayrollOutsourcing from '../components/PayrollOutsourcing/Main';
import BackgroundChecks from '../components/BackgroundChecks/Main';
import Account from '../components/Account/Main';
import ConsultancyServices from '../components/ConsultancyServices/Main';
import Clients from '../components/Clients/Main';
import Login from '../components/Login/Main';
import AdminLogin from '../components/AdminLogin/Main';
import AddJob from '../components/AddJob/Main';
import AddBlog from '../components/AddBlog/Main';
import AddGalleryPicture from '../components/AddGalleryPicture/Main';
import AddCareerListing from '../components/AddCareerListing/Main';
import Gallery from '../components/Gallery/Main';
import Register from '../components/Register/Main';
import JobDetail from '../components/JobDetail/Main';
import EditJob from '../components/EditJob/Main';
import Resumes from '../components/Resumes/Main';
import UserManagement from '../components/UserManagement/Main';
import NexfordUniversity from '../components/NexfordUniversity/Main';
import AddNewAdmin from '../components/AddNewAdmin/Main';
import EmployerOfRecord from '../components/EmployerOfRecord/Main';
// import Resources from '../components/Resources/Main';

function index() {
    return (
        <>
            <Routes>
                <Route path="/" element={<Homeone />} />
                <Route path="/about" element={<About />} />
                <Route path="/jobs" element={<Jobs />} />
                <Route path="/jobs/:id" element={<JobDetail />} />
                <Route path="/jobs/:id/edit" element={<EditJob />} />
                <Route path="/services" element={<Services />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/career-listings" element={<CareerListing />} />
                <Route path="/resumes" element={<Resumes />} />
                <Route path="/users" element={<UserManagement />} />
                <Route path="/blog/:id" element={<BlogDetails />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/executive-search" element={<ExecutiveSearch />} />
                <Route path="/recruitment" element={<Recruitment />} />
                <Route path="/account" element={<Account />} />
                <Route path="/payroll-outsourcing" element={<PayrollOutsourcing />} />
                <Route path="/background-checks" element={<BackgroundChecks />} />
                <Route path="/employer-of-record" element={<EmployerOfRecord />} />
                <Route path="/consultancy-services" element={<ConsultancyServices />} />
                <Route path="/our-clients" element={<Clients />} />
                <Route path="/login" element={<Login />} />
                <Route path="/admin-login" element={<AdminLogin />} />
                <Route path="/register" element={<Register />} />
                <Route path="/add-job" element={<AddJob />} />
                <Route path="/add-blog" element={<AddBlog />} />
                <Route path="/add-admin" element={<AddNewAdmin />} />
                <Route path="/add-career-listing" element={<AddCareerListing />} />
                <Route path="/nexford-university" element={<NexfordUniversity />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/add-gallery-picture" element={<AddGalleryPicture />} />
            </Routes>
        </>
    )
}

export default index