import React, { useEffect, useState } from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Servicepage from '../Menservice/Servicepage'
import Footer from '../Footer/Main'
import { Link } from 'react-router-dom'
import { getStrapiData } from '../../api.controller'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

function Main() {
    const [data, setData] = useState();

    useEffect(() => {
        getStrapiData("employer-of-record-page").then(response => {
            console.log(response);
            setData(response.data);
        });
    }, []);
    return (
        <>
            <div className="page-wrapper">
                <Header />
                <Breadcrumb data={data} subtitle="Home" title="Employer of Record" />
                <section className="service-one service-one--page">
                    <div className="container">
                        <ReactMarkdown rehypePlugins={[rehypeRaw]} transformImageUri={(src, alt, title) => {
                            return `${process.env.REACT_APP_CMS_BASE_API}${src}`;
                        }}>{data && data.content}</ReactMarkdown>
                    </div>
                </section >
                <Footer />
            </div >

        </>
    )
}

export default Main