import React, { useEffect, useState } from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Contactform from '../Mencontact/Contactform'
import Map from '../Mencontact/Map'
import Footer from '../Footer/Main'
import { getStrapiData } from '../../api.controller'

function Main() {
  const [data, setData] = useState();

  useEffect(() => {
    getStrapiData("contact-page").then(response => {
      console.log(response);
      setData(response.data);
    });
  }, []);

  return (
    <div>
      <div className="page-wrapper">
        <Header />
        <Breadcrumb data={data} subtitle="Home" title="Contact" />
        <Contactform />
        <Map />
        <Footer />
      </div>
    </div>
  )
}

export default Main