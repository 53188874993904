import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import { debounce } from 'lodash';
import { showNotification } from '../../utils.ts';
import { deleteJob, getJobs } from '../../api.controller';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

function Jobfrom() {
    const [user, setUser] = useState();
    const [searchParams, setSearchParams] = useState({
        job: "",
        location: "",
        category: ""
    });
    const location = useLocation();
    const [jobs, setJobs] = useState([]);


    useEffect(() => {
        const stringifiedUser = localStorage.getItem("userData");
        const _user = JSON.parse(stringifiedUser);
        setUser(_user);
        fetchJobs();
    }, []);

    useEffect(() => {
        fetchJobs();
    }, [searchParams]);

    const handleJobChange = useCallback(debounce((job) => {
        setSearchParams((prev) => ({ ...prev, job }));
    }, 800), []);

    const handleLocationChange = useCallback(debounce((location) => {
        setSearchParams((prev) => ({ ...prev, location }));
    }, 800), []);

    const _deleteJob = async (jobId) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Please wait",
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });
                deleteJob(jobId).then((response) => {
                    Swal.fire({
                        title: "Deleted!",
                        text: "Job has been deleted.",
                        icon: "success",
                        willClose: () => {
                            fetchJobs();
                        }
                    });
                }).catch(err => {
                    console.log(err);
                    Swal.fire({
                        title: "Could not delete Job",
                        text: "Job Could not be deleted",
                        icon: "success",
                        willClose: () => {
                            fetchJobs();
                        }
                    });
                });
            }
        });
    }

    const fetchJobs = async () => {
        const queryParams = new URLSearchParams(location.search);
        const category = queryParams.get("category");
        console.log("category", category);
        try {
            const response = await getJobs(searchParams, category);
            setJobs(response?.data);
        } catch (error) {
            const message = error?.response?.data?.message || "Was unable to fetch";
            showNotification("Error", message, "error");
            console.log(error);
        }
    }

    // Define an array of job objects


    return (
        <div className="jobs">
            <div className="container">
                <div className="d-flex justify-content-end">
                    {user && (user?.userType === "COMPANY" || user?.userType === "ADMIN") &&
                        <Link to="/add-job" className="hiredots-btn mb-30">
                            Upload A Job <i className="fas fa-plus ms-3" />
                        </Link>}
                </div>
                <form className="contact-form-validated" action="inc/sendemail.php" noValidate="novalidate">
                    <div className="jobs__form wow fadeInUp animated" data-wow-duration="1500ms">
                        <div className="jobs__group row gutter-y-20">
                            <div className="col-md-6">
                                <div className="job__control Jobs__control--full">
                                    <input type="text" name="name" placeholder="All Jobs" onChange={(e) => {
                                        const job = e.target.value;
                                        handleJobChange(job);
                                    }} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="jobs__control Jobs__control--full">
                                    <select type="text" name="location" onChange={(e) => {
                                        const location = e.target.value;
                                        handleLocationChange(location);
                                    }}>
                                        <option value=''>Company Location</option>
                                        <option value='Ahafo'>Ahafo</option>
                                        <option value='Ashanti'>Ashanti</option>
                                        <option value='Bono'>Bono</option>
                                        <option value='Bono East'>Bono East</option>
                                        <option value='Central'>Central</option>
                                        <option value='Eastern'>Eastern</option>
                                        <option value='Greater Accra'>Greater Accra</option>
                                        <option value='North East'>North East</option>
                                        <option value='Northern'>Northern</option>
                                        <option value='Oti'>Oti</option>
                                        <option value='Savannah'>Savannah</option>
                                        <option value='Upper East'>Upper East</option>
                                        <option value='Upper West'>Upper West</option>
                                        <option value='Volta'>Volta</option>
                                        <option value='Western'>Western</option>
                                        <option value='Western North'>Western North</option>

                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="jobs__form__checkbox">
                        {/* Checkbox items */}
                    </div>
                </form>
                <div className="jobs-list">
                    {/* Map over jobs array and generate job entries */}
                    {jobs.length > 0 ? jobs.map((job, index) => {
                        const duration = formatDistanceToNow(job.createdAt, { addSuffix: true });
                        const salary = new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'GHS',
                        }).format(job.salary);

                        return <div key={index} className="jobs-list__card pb-3 mt-3">
                            <div className="jobs-list__left">
                                <div className="jobs-list__logo">
                                    <img style={{ height: "85%", maxWidth: "100%" }} src={`${process.env.REACT_APP_FILE_BASE_URL}/${job.companyLogo}`} alt="" />
                                </div>
                                <div className="jobs-list__content my-auto">
                                    <span className="jobs-list__time">
                                        <i className="fas fa-clock" /> Posted {duration}
                                    </span>
                                    <h3 className="jobs-list__title">
                                        <Link to={`/jobs/${job._id}`}>{job.jobTitle}</Link>
                                    </h3>
                                    <span className="jobs-list__location">
                                        {job.location}
                                    </span>
                                </div>
                            </div>
                            <div className="jobs-list__right">
                                <span className="jobs-list__price">
                                    <b>{salary}</b> Per Month
                                </span>
                                <Link to={`/jobs/${job._id}`} className="hiredots-btn">
                                    Apply Now
                                </Link>

                                {user && user?.userType == "ADMIN" && <>
                                    <Link to={`#`} onClick={(event) => {
                                        event.preventDefault();
                                        _deleteJob(job._id);
                                    }} >
                                        <i className="fas fa-2x ms-3 p-3 fa-trash text-danger" />
                                    </Link>
                                    <Link to={`/jobs/${job._id}/edit`}  >
                                        <i className="fas fa-2x ms-3 p-3 fa-edit text-primary" />
                                    </Link>

                                </>}
                            </div>
                        </div>
                    }) : <h1>No Jobs Available</h1>}
                </div>
            </div>
        </div>
    );
}

export default Jobfrom;
