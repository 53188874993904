import React, { useEffect, useState } from 'react'
import Header from '../Header/Main'
import Breadcrumb from '../Breadcrumb/Main'
import Servicepage from '../Menservice/Servicepage'
import Footer from '../Footer/Main'
import { getJobSeekersWithResume, getStrapiData } from '../../api.controller'
import { Link } from 'react-router-dom'

function Main() {
    const [data, setData] = useState();
    const [resumes, setResumes] = useState([]);
    useEffect(() => {
        getStrapiData("executive-search-page").then(response => {
            setData(response.data);
        }).catch(err => {
            console.log(err);
        });

        getJobSeekersWithResume().then(
            response => {
                console.log(response.data);
                setResumes(response?.data?.data);
            }
        ).catch(error => {
            console.log(error);
        });
    }, []);

    return (
        <>
            <div className="page-wrapper">
                <Header />
                <Breadcrumb data={data} subtitle="Home" title="Resumes" />
                <section className="service-one service-one--page">
                    <div className="container">
                        <table className="table">
                            <thead>
                                <th>Name</th>
                                <th>Resume</th>
                                <th>Date Modified</th>
                            </thead>
                            <tbody>
                                {resumes.map((resume) => {
                                    return <tr>
                                        <td>{resume.fullName}</td>
                                        <td>{resume.file ? <Link target="_blank" to={`${process.env.REACT_APP_FILE_BASE_URL}/${resume.file}`}>{resume.file}</Link> : "N/A"} </td>
                                        <td>{resume.updatedAt}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </section >
                <Footer />
            </div >

        </>
    )
}

export default Main