import React from 'react';
import Lottie from "lottie-react";
import loadingAnimation from "./loading.json";

const LoadingPage = () => {
    return <div className="d-flex flex-column justify-content-center align-items-center position-absolute w-100 h-100 ">
        <div className="d-flex flex-column align-items-center" style={{ width: 200, height: 200, }}>
            <Lottie animationData={loadingAnimation} loop={true} />
            <div>Please Wait...</div>
        </div>
    </div>
}

export default LoadingPage;